<!-- footer start -->
<footer class="resume copyright copyright-bg inner-pages-footer">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="link link-horizontal  text-center mb-3">
                    <img style="width: 10%" alt="" class="img-fluid" src="assets/images/logo/logoTransparent.png">
                </div>
            </div>
            <div class="col-12">
                <div class="link link-horizontal">
                    <ul class="justify-content-center">
                        <li><a class="copyright-text" href="#">Home</a></li>
                        <li><a class="copyright-text" href="#">Nový</a></li>
                        <li><a class="copyright-text" href="#">Dostupnost</a></li>
                        <li><a class="copyright-text" href="#">Naše Dostupná Vozidla </a></li>
                        <li><a class="copyright-text" href="#">Informace</a></li>
                        <li style="color: black; font-weight: lighter; font-size: 0.7em" class=" ml-2 mt-1 copyright-text">automobileconsulting
                            ©2021_automobileconsulting</li>
                    </ul>
                </div>
            </div>
            <div class="col-12">
                <div class="text-center">
                    <div class="social-link link-horizontal">
                        <ul class="justify-content-center">
                            <li><a class="copyright-text" href="#"><i aria-hidden="true" class="fa fa-facebook"></i></a>
                            </li>
                            <li><a class="copyright-text" href="#"><i aria-hidden="true" class="fa fa-google"></i></a>
                            </li>
                            <li><a class="copyright-text " href="#"><i aria-hidden="true"
                                                                       class="fa fa-map-marker"></i></a></li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer end -->


