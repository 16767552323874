<section class="ecommerce pt-0 ecommerce-home">
    <owl-carousel-o [options]="ecombannerOptions" class="ecommerce-home-slider">
        <ng-container *ngFor="let data of banner">
            <ng-template carouselSlide>
                <div class="item-class" [ngStyle]="{'background-image': 'url('+ data.img +')'}">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-5 col-lg-6 col-sm-8">
                                <div class="contain">
                                    <div>
                                        <h1>{{data.title}}</h1>
                                        <p>{{data.description}}</p>
                                        <a  class="btn btn-default primary-btn  radius-0" href="#">{{data.btn}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</section>
