import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ecommerce-banner-slider',
  templateUrl: './ecommerce-banner-slider.component.html',
  styleUrls: ['./ecommerce-banner-slider.component.scss']
})
export class EcommerceBannerSliderComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
  banner = [
    {
      img: 'assets/images/voitures/porschespl.jpeg',
      title: 'Naše kolekce',
      description: 'můžete mě kontaktovat na adrese : ',
      btn: 'Přístup k nabídkám',
      link: '/e-commerce/left-sidebar/collection/new'
    },
    {
      img: 'assets/images/voitures/bmw.jpeg',
      title: 'Naše kolekce',
      description: 'můžete mě kontaktovat na adrese : ',
      btn: 'Přístup k nabídkám',
      link: '/e-commerce/left-sidebar/collection/trending'
    }
  ]

  ecombannerOptions = {
    items: 1,
    nav: true,
    navClass: ['owl-prev', 'owl-next'],
    navText: ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>'],
    dots: false,
    autoplay: true,
    slideSpeed: 300,
    loop: true
  }

  offers = [
    {
      img: 'assets/images/voitures/508gt.jpeg'
    },
    {
      img: 'assets/images/voitures/porschescayenne.jpeg'
    },
    {
      img: 'assets/images/voitures/porschespl.jpeg'
    }
  ]


}
