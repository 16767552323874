<section class="down-banner">
    <div class="container">
        <div class="row">
            <div class="col-12 p-0">
                <div class="small-banner">
                    <img alt="full banner" class="bg-img" src="assets/images/voitures/mini-banner.png">
                </div>
            </div>
            <div class="container">
                <div class="banner-contain">
                    <div>
                        <h3 style="color: white">Další dostupné nabídky</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
