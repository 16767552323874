<div class="collection-mobile-back" >
  <span class="filter-back" (click)="toggleFilter()">
    <i class="fa fa-angle-left" aria-hidden="true"></i> back
</span>
</div>
<div class="collection-collapse-block top_filter border-0 open">
  <h3 class="collapse-block-title">Category</h3>
  <div class="collection-collapse-block-content">
    <div class="collection-brand-filter">
      <ul class="category-list">
        <li><a [routerLink]="'/e-commerce/left-sidebar/collection/new'">Audi</a></li>
        <li><a [routerLink]="'/e-commerce/left-sidebar/collection/trending'">Mercedes</a></li>
        <li><a [routerLink]="'/e-commerce/left-sidebar/collection/western'">Porsche</a></li>
      </ul>
    </div>
  </div>
</div>
