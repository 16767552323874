<section class="parallax-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 p-0">
                <div class="full-banner" [ngStyle]="{'background-image': 'url(assets/images/voitures/audi-a1Banner.jpeg)'}">
                </div>
            </div>
            <div class="container">
                <div class="banner-contain">
                    <div>
                        <h2>K dispozici 7 dní v týdnu</h2>
                        <p> Můžete mě kontaktovat na adrese :</p>
                        <a class="btn btn-default primary-btn  radius-0 mt-3" href="#">Zobrazit všechny</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
